<template>
  <CycleCardList
    :title="textContent.referenceTitle"
    modifiers="nomarker"
    data-testid="myInsuranceCard"
  >
    <template #list-items>
      <CycleListItem data-testid="myInsuranceCardReference">
        <label>{{ textContent.referenceLabel }}</label>
        <!-- MYZ-989: Dirty temporary solution. Will be reverted once Biztalk sends correct name -->
        <p>{{ reference === 'Vuurwapen' ? 'Jachtuitrusting' : reference }}</p>
      </CycleListItem>
      <CycleListItem v-if="licensePlate" data-testid="myInsuranceCardLicensePlate">
        <label>{{ textContent.licensePlateLabel }}</label>
        <p>{{ licensePlate }}</p>
      </CycleListItem>
      <CycleListItem v-if="constructionYear" data-testid="myInsuranceCardConstructionYear">
        <label>{{ textContent.constructionYearLabel }}</label>
        <p>{{ constructionYear }}</p>
      </CycleListItem>
      <CycleListItem v-if="householdComposition" data-testid="myInsuranceCardHouseholdComposition">
        <label>{{ textContent.householdCompositionLabel }}</label>
        <p>{{ householdComposition }}</p>
      </CycleListItem>
      <CycleListItem v-if="insuredAmount" data-testid="myInsuranceCardInsuredAmount">
        <label>{{ textContent.insuredAmountLabel }}</label>
        <p>{{ insuredAmount }}</p>
      </CycleListItem>
    </template>
  </CycleCardList>
</template>

<script>
import { mapValues } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import { CycleCardList, CycleListItem } from '@aon/cycle'
import { formatCurrency } from '@/services/formatting'

export default {
  name: 'MyInsuranceCard',
  components: {
    CycleCardList,
    CycleListItem
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters(['getPolicyInfo']),
    textContent() {
      return mapValues(this.fields, (item) => item.value ?? item)
    },
    policyNumber() {
      return this.$route?.query?.insurance
    },
    policyInfo() {
      return this.getPolicyInfo(this.policyNumber)
    },
    reference() {
      return this.policyInfo?.reference || this.policyInfo?.number
    },
    licensePlate() {
      return this.policyInfo?.licensePlate || this.policyInfo?.policy?.item?.licensePlate
    },
    constructionYear() {
      return this.policyInfo?.policy?.item?.vehicleData?.constructionYear
    },
    householdComposition() {
      return this.policyInfo?.policy?.item?.householdComposition
    },
    insuredAmount() {
      return formatCurrency({
        amount: this.policyInfo?.policy?.item?.liabilityConfiguration?.insuredAmount,
        locale: this.$store.getters.currentLanguage
      })
    }
  },
  created() {
    this.fetchPolicyInfo(this.policyNumber)
  },
  methods: {
    ...mapActions(['fetchPolicyInfo'])
  }
}
</script>
