//
//
//
//

import icons from './icons'

export default {
  name: 'CycleIcon',
  props: {
    /**
     * Use `icon` instead
     *
     * @deprecated since version v3.4.0
     */
    modifiers: {
      type: String,
      required: false,
      validator: (icon) => icons.includes(icon)
    },
    /**
     * Whether to render a hexagon around the icon
     */
    hexagon: {
      type: Boolean,
      default: false
    },
    /**
     * Name of the icon to show
     *
     * The name should be in the list of icons known by Core UI
     * @see src\components\elements\CycleIcon\icons.js
     */
    icon: {
      type: String,
      required: false,
      validator: (icon) => icons.includes(icon)
    }
  },
  computed: {
    iconClass() {
      return `icon--${this.icon ?? this.modifiers}`
    }
  }
}
