<template>
  <RapidFormCheckoutWrapper
    v-if="hasOnlyOneProduct && !hasSingleCoverBlock"
    :label="getProduct('name')"
    :tooltip="getProduct('tooltip')"
    :disabled="!getProduct('canSelect')"
    :model-value="getProduct('selected')"
    :field-key="fieldKey"
    :name="getProduct('code')"
    :description="getProduct('description')"
    :is-loading="isLoading"
    :has-toggle-tooltip="hasToggleTooltip"
    @change="updateValue"
  >
    <template #loading><slot name="loading" /></template>
  </RapidFormCheckoutWrapper>
  <CycleCompareCoverage
    v-else-if="hasProducts"
    :show-price-on-top="showPriceOnTop"
    :show-buttons="showButtons"
    :show-check-mark="isCheckMarkEnabled"
    :has-single-cover-block="hasSingleCoverBlock"
  >
    <CycleCoverage
      v-for="product in products"
      :key="product.code"
      :title="product.name"
      :tooltip="product.tooltip"
      @change="updateValue"
      @click="updateValue"
      :link="linkText"
      :price="getPrice(product)"
      :payment-term="product.pricePaymentTerm"
      :list="getDescription(product)"
      :disabled="!product.canSelect && !hasSingleCoverBlock"
      :selection-disabled="isSelectionDisabled(product)"
      :bodytext="product.bodyText"
      :model-value="value"
      :value="product.code"
      :is-highlighted="product.isHighlighted"
      :show-toggle="isToggleVisible(product)"
      :modifiers="modifiers"
      :alternative-offers="product.alternativeOffers"
      :recommended-text="product.recommendedText"
      :selected-text="getPhrase('funnels.buttons.selected-insurance')"
      :disabled-text="getPhrase('funnels.buttons.disabled-insurance')"
      :highlighted-coverage-toggle-open-text="getPhrase('funnels.buttons.open-action')"
      :highlighted-coverage-toggle-close-text="getPhrase('funnels.buttons.close-action')"
      :most-popular-text="getInsentiveText(product)"
      v-bind="getParams(product)"
      :transition-params="getTransitionParams()"
    >
      <template #input>
        <template v-for="field in inputFields">
          <RapidFormField
            v-if="isNKCCamperBepertkCasco(product)"
            :field-definition="field"
            :field-set="field.fieldSet"
            :key="field.fieldSet + '_' + field.key"
            :show-if="field.showIf"
            :group-show-if="getGroupFieldCondition(field.fieldGroup)"
            :get-value="field.getValue"
            :dynamic-options="field.optionsFromFormDefinition"
            :dynamic-label="parsableValue(field.label)"
            :dynamic-content="parsableValue(field.content)"
            :enable-if="field.enableIf"
            :visible-if="1"
          />
        </template>
      </template>
    </CycleCoverage>
  </CycleCompareCoverage>
</template>

<script>
import { CycleCompareCoverage, CycleCoverage, CycleHtml } from '@aon/cycle'
import RapidFormCheckoutWrapper from './RapidFormCheckoutWrapper'
import { mapActions, mapGetters } from 'vuex'
import { dictionaryMixin } from '@/mixins/Dictionary'

export default {
  name: 'RapidFormProductWrapper',
  components: {
    RapidFormCheckoutWrapper,
    CycleCompareCoverage,
    CycleCoverage,
    CycleHtml,
    RapidFormField: () => import('../RapidFormField.vue')
  },
  mixins: [dictionaryMixin],
  props: {
    value: {
      type: [String, Boolean],
      required: true
    },
    isLoading: {
      required: false,
      type: Boolean
    },
    modifiers: {
      type: [String, Array],
      required: false
    }
  },
  computed: {
    ...mapGetters('rapidFormPlayer', [
      'businessData',
      'activeStep',
      'getFieldsByKey',
      'getFieldGroup'
    ]),
    products() {
      return this.businessData[`${this.activeStep?.key}.advice.products`]?.items
    },
    fieldKey() {
      return `${this.activeStep?.key}.advice.products`
    },
    showPriceOnTop() {
      return this.products.some((product) => product.showPriceOnTop === true)
    },
    showButtons() {
      return this.products.some((product) => product.showButtons === true)
    },
    isBotEnabled() {
      return this.modifiers?.includes('BestOfferTool')
    },
    isCheckMarkEnabled() {
      return this.modifiers?.[0]?.includes('BestOfferToolCheckMark')
    },
    hasSingleCoverBlock() {
      return this.modifiers?.[0]?.includes('BestOfferToolSingleCoverblock')
    },
    inputFields() {
      // hardcoded form field specific for nkc camper. if another product needs something like this, a better solution needs to be built with sitecore
      const inputFields = [
        this.getFieldsByKey('compose-insurance.dayvalue.dayvalue'),
        this.getFieldsByKey('compose-insurance.dayvalue.dayvaluefromtaxationvalue')
      ]
      return inputFields.filter(Boolean)
    },
    linkText() {
      if (this.isBotEnabled || this.isCheckMarkEnabled) {
        return this.getPhrase('funnels.buttons.select-insurance')
      }
      return this.getPhrase('funnels.buttons.i-want-this-insurance')
    },
    hasToggleTooltip() {
      return this.modifiers?.[0]?.includes('toggle-tooltip')
    },
    hasProducts() {
      return this.products?.length > 0
    },
    hasOnlyOneProduct() {
      return this.products?.length === 1
    }
  },
  beforeMount() {
    const selectedProduct = this.products?.find((prod) => prod.selected)
    if (selectedProduct) {
      this.updateField({
        value: selectedProduct.code,
        fieldKey: `${this.activeStep?.key}.advice.products`
      })
    }
  },
  methods: {
    ...mapActions('rapidFormPlayer', ['updateField']),
    updateValue(value) {
      if (this.products?.length === 1) {
        this.$emit('change', value ? this.products[0].code : false)
      } else {
        this.$emit('change', value)
      }
      this.$emit('blur')
    },
    getParams(product) {
      return product.companyDisplayName ? { 'test-data-company': product.companyDisplayName } : null
    },
    getGroupFieldCondition(val) {
      if (!val) {
        return undefined
      }

      const fieldGroup = this.getFieldGroup(val)
      return fieldGroup?.showIf
    },
    parsableValue(val) {
      return val ? '`' + val + '`' : val
    },
    getDescription(product) {
      // We could use https://marked.js.org/ in future implementations, we only do bold italic and monospace here
      return (
        product?.usPs?.map((item) => {
          item.text = item?.text
            ?.replace(/\*\*(.*)\*\*/gim, '<strong>$1</strong>')
            ?.replace(/_(.*)_/gim, '<em>$1</em>')
            ?.replace(/`(.*)`/gim, '<code>$1</code>')
          return item
        }) ?? []
      )
    },
    getInsentiveText(product) {
      if (product.showTag) {
        return this.getPhrase('general-most-chosen')
      }
    },
    isNKCCamperBepertkCasco(product) {
      // 'NKCCamper-WA-Beperkt-Casco'
      return /AD23BB3F-F33D-432F-90D1-00693EC604A1/i.test(product?.code)
    },
    getTransitionParams() {
      return this.getFieldsByKey(this.fieldKey)?.numberTransition
    },
    getPrice(product) {
      return this.hasSingleCoverBlock || product?.canSelect ? product?.price : null
    },
    isToggleVisible(product) {
      return (
        (product.showToggle || this.isBotEnabled || this.isCheckMarkEnabled) &&
        !this.hasSingleCoverBlock
      )
    },
    isSelectionDisabled(product) {
      return (
        (!product.canSelect || this.hasSingleCoverBlock) && this.isNKCCamperBepertkCasco(product)
      )
    },
    getProduct(key) {
      const products = this.products ?? []
      return products?.length > 0 ? products[0][key] : null
    }
  }
}
</script>
