//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleHtml } from '@/components/base'
import { CycleButton, CycleIcon } from '@/components/elements'
import { DocumentsHeading } from '@/components/atoms'
import { BusyIndicator, InboxItem, EmptyTablePlaceholder } from '@/components/molecules'
import { CyclePagination } from '@/components/cycle-pagination'
import { CycleLinkIcon } from '@/components/cycle-link'
import { CycleFormConfirmation } from '@/components/functional'
import { CycleDate } from '@/filters'
import { intlMixin } from '@/mixins'
import { buildDownloadLink } from '@/services/Document'
import { DocumentStatusTypes } from './constants/my-documents-constants'
import { CycleMyDocument } from './CycleMyDocument'
import { LIST_OF_CLASS_NAMES } from './constants.js'
import { mapGetters, mapActions } from 'vuex'
import MyDocumentsState from './store'

export default {
  name: 'CycleMyDocuments',
  components: {
    CyclePagination,
    CycleButton,
    CycleLinkIcon,
    CycleMyDocument,
    CycleHtml,
    DocumentsHeading,
    InboxItem,
    EmptyTablePlaceholder,
    BusyIndicator,
    CycleFormConfirmation,
    CycleIcon
  },
  filters: {
    CycleDate
  },
  mixins: [intlMixin],
  props: {
    textContent: {
      type: Object,
      required: false,
      default: () => ({})
    },
    documents: {
      type: Array,
      required: false,
      default: () => []
    },
    totalNumberOfLines: {
      type: Number,
      required: true
    },
    numberOfLinesOnPage: {
      type: Number,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: false
    },
    responseStatus: {
      type: Object,
      default: () => {}
    },
    pageNumber: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      responseText: {},
      selectedDetails: [],
      DocumentStatusTypes
    }
  },
  beforeCreate() {
    this.$store.registerModule('myDocuments', MyDocumentsState, {
      preserveState: !!this.$store.state['myDocuments']
    })
  },
  destroyed() {
    this.$store.unregisterModule('myDocuments')
  },
  computed: {
    ...mapGetters('myDocuments', ['getDocumentStatus', 'getDocumentStatusIcon']),
    hasPagination() {
      if (this.numberOfLinesOnPage === 0) {
        return false
      }

      return this.totalNumberOfLines / this.numberOfLinesOnPage > 1
    },
    currentPage() {
      return this.pageNumber
    }
  },
  watch: {
    documents: {
      immediate: true,
      handler() {
        this.documents.forEach((document) => {
          const documentStatus = document.status
          const documentID = document.id
          this.updateDocumentStatus(documentID, documentStatus)
        })
      }
    }
  },
  methods: {
    ...mapActions('myDocuments', ['setDocumentStatus']),
    buildDownloadLink,
    onResponseSubmit(document) {
      if (!this.responseText[document.id]) {
        return
      }

      this.$emit('response', {
        message: this.responseText[document.id],
        document
      })
      // optimistic clean-up. Error and Success paths need to be implemented in next story.
      // this.responseText[document.id] = undefined
    },
    setColumnTitle(columnTitle) {
      return columnTitle ? columnTitle : '&nbsp;'
    },
    setPageNumber(e) {
      this.$emit('page-change', e)
    },
    isPartOfClassList(event) {
      return LIST_OF_CLASS_NAMES.includes(event.target.className)
    },
    handleDetailClick(index, event) {
      if (this.isPartOfClassList(event)) return
      this.updateVisibilityOfDetail(index)
    },
    updateVisibilityOfDetail(index) {
      if (this.selectedDetails.includes(index)) {
        this.selectedDetails.splice(this.selectedDetails.indexOf(index), 1)
      } else {
        this.selectedDetails.push(index)
      }
    },
    // Do not set the DocumentStatus if the document is already read or if the document status is hidden.
    // This prevents documents appearing unread after page changes or when documents are filtered
    // or when the status should stay hidden.
    async updateDocumentStatus(documentID, documentStatus = DocumentStatusTypes.Unknown) {
      const storedDocumentStatus = this.getDocumentStatus(documentID)
      if (
        storedDocumentStatus === DocumentStatusTypes.Read ||
        storedDocumentStatus === DocumentStatusTypes.Hidden
      ) {
        return
      }

      await this.setDocumentStatus({ documentID, documentStatus })
    },
    hideDocumentStatus(documentID) {
      return (
        this.getDocumentStatus(documentID) == undefined ||
        this.getDocumentStatus(documentID) === DocumentStatusTypes.Hidden
      )
    },
    documentsItemClass(documentID) {
      return this.getDocumentStatus(documentID) === DocumentStatusTypes.Unread
        ? 'documents__item--unread'
        : ''
    }
  }
}
