<template>
  <CycleContentBlock v-if="isListContainerVisible" :contrast-color="!isTransparent">
    <template v-if="hasTitle" #header>
      <CycleHeading :weight="headingWeight">{{ title }}</CycleHeading>
    </template>
    <template v-if="hasBodyText || hasList" #body>
      <CycleHtml v-if="hasBodyText" :html="bodyText" />
      <CycleList v-if="hasList" modifiers="flat">
        <CycleListItem v-for="listItem in listItems" :key="listItem.id">
          <CycleLinkIcon
            v-if="hasLink(listItem) && hasText(listItem) && hasIcon(listItem)"
            class="link link--icon"
            :modifiers="getIcon(listItem)"
            :href="getLinkHref(listItem)"
            :target="getLinkTarget(listItem)"
          >
            {{ getText(listItem) }}
          </CycleLinkIcon>
          <template v-else>
            <CycleIcon v-if="hasIcon(listItem)" class="icon--list" :icon="getIcon(listItem)" />
            <span v-if="hasText(listItem)">{{ getText(listItem) }}</span>
          </template>
        </CycleListItem>
      </CycleList>
    </template>
    <template v-if="hasFooterText" #footer>
      <CycleHtml :html="footerText" />
    </template>
  </CycleContentBlock>
</template>
<script setup>
import { defaultHeadingWeight } from '@/constants/Defaults'
const props = defineProps({
  fields: {
    type: Object,
    default: () => ({})
  }
})

const getText = (listItem) => listItem.fields?.text?.value
const hasText = (listItem) => getText(listItem)?.length > 0
const getIcon = (listItem) => listItem.fields?.icon?.value
const hasIcon = (listItem) => getIcon(listItem)?.length > 0
const getLinkHref = (listItem) => listItem.fields?.link?.value?.href
const getLinkTarget = (listItem) => listItem.fields?.link?.value?.target
const hasLink = (listItem) => getLinkHref(listItem)?.length > 0

const title = props.fields?.heading?.value
const hasTitle = title?.length > 0
const bodyText = props.fields?.bodyText?.value
const hasBodyText = bodyText?.length > 0
const footerText = props.fields?.footerText?.value
const hasFooterText = footerText?.length > 0
const listItems = props.fields?.listItems || []
const hasList = listItems?.length > 0
const isTransparent = props.fields?.isTransparent?.value
const isListContainerVisible = hasTitle || hasBodyText || hasList || hasFooterText
const headingWeight = Number.parseInt(props.fields?.weight?.value) || defaultHeadingWeight
</script>
