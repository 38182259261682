<template>
  <CycleCardList
    v-if="showDiscountCard"
    :title="textContent.discountTitle"
    modifiers="nomarker"
    data-testid="discountCard"
  >
    <template #list-items>
      <CycleListItem v-if="claimFreeYears" data-testid="discountCardclaimFreeYears">
        <label>{{ textContent.claimFreeYearsLabel }}</label>
        <p>{{ claimFreeYears }}</p>
      </CycleListItem>
      <CycleListItem v-if="noClaimDiscount" data-testid="discountCardnoClaimDiscount">
        <label>{{ textContent.noClaimDiscountLabel }}</label>
        <p>{{ noClaimDiscount }}</p>
      </CycleListItem>
      <CycleListItem v-if="step" data-testid="discountCardstep">
        <label>{{ textContent.stepLabel }}</label>
        <p>{{ step }}</p>
      </CycleListItem>
      <CycleListItem
        v-if="drivingSkillsCertificate"
        data-testid="discountCarddrivingSkillsCertificate"
      >
        <label>{{ textContent.drivingSkillsCertificateLabel }}</label>
        <p>{{ drivingSkillsCertificate }}</p>
      </CycleListItem>
      <CycleListItem v-if="membership" data-testid="discountCardmembership">
        <label>{{ textContent.membershipLabel }}</label>
        <p>{{ membership }}</p>
      </CycleListItem>
      <CycleListItem v-if="winterBreak" data-testid="discountCardwinterBreak">
        <label>{{ textContent.winterBreakLabel }}</label>
        <p>{{ winterBreak }}</p>
      </CycleListItem>
    </template>
  </CycleCardList>
</template>

<script>
import { mapValues } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import { CycleListItem, CycleCardList } from '@aon/cycle'

export default {
  name: 'DiscountCard',
  components: {
    CycleCardList,
    CycleListItem
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters(['getPolicyInfo']),
    textContent() {
      return mapValues(this.fields, (item) => item.value ?? item)
    },
    policyNumber() {
      return this.$route?.query?.insurance
    },
    policyInfo() {
      return this.getPolicyInfo(this.policyNumber)
    },
    showDiscountCard() {
      return this.policyInfo?.policy?.item !== undefined
    },
    claimFreeYears() {
      return this.policyInfo?.policy?.item?.claimFreeYears
    },
    noClaimDiscount() {
      return this.policyInfo?.policy?.item?.noClaimDiscount
    },
    step() {
      return this.policyInfo?.policy?.item?.step
    },
    isMotor() {
      return this.policyInfo?.productType === 'MotorCycle'
    },
    drivingSkillsCertificate() {
      return this.isMotor && this.textContent?.drivingSkillsCertificateText
    },
    membership() {
      return (
        this.isMotor &&
        (this.policyInfo?.policy?.item?.membership || this.textContent.doesNotApplyText)
      )
    },
    winterBreak() {
      return (
        this.isMotor &&
        (this.policyInfo?.policy?.item?.winterBreak || this.textContent.doesNotApplyText)
      )
    }
  },
  created() {
    this.fetchPolicyInfo(this.policyNumber)
  },
  methods: {
    ...mapActions(['fetchPolicyInfo'])
  }
}
</script>
