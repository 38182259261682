import { getEnv } from '../globals'

/**
 * Service relevant to documents as retrieved from the docstore
 *
 * Known document types:
 *
 *  - 0012: Polisblad
 *  - 0034: Groene kaart
 *  - 1031: Poliscorrespondentie
 */

/**
 * @param {Object} document - any type of document containing a type code
 * @return {Boolean} whether the document is a policy schedule or not
 */
export const isPolicySchedule = ({ type }) => !!type && type.code == 12

export const isGreenCard = ({ type }) => !!type && type.code == 34

/**
 * @param {Object} document - any type of document containing a document id
 * @return {String} url pointing to download endpoint
 */
export const buildDownloadLink = ({ id }) => {
  const nuxtEnv = getEnv('NUXTJSS_DOMAIN')
  const scEnv = getEnv('sitecoreUrl')

  return `${nuxtEnv ? '//' + nuxtEnv : scEnv}/aonapi/documentservice/${id}`
}

/**
 * @param {String} relativeUrl - any type of relative url which includes
 * "/aonapi/documentservice/policysheet/{encryptedPolicyNumber}"
 * @return {String} url pointing to download endpoint
 */
export const buildDownloadLinkPolicySheet = (relativeUrl) => {
  const scEnv = getEnv('sitecoreUrl')

  return `${scEnv}${relativeUrl}`
}

/**
 * @param {String} policyNumber - policy number to filter for
 */
export const isForPolicy = (policyNumber) =>
  /**
   * @param {Object} document - any type of document containing a document reference number
   * @return {Boolean}
   */
  (document) =>
    document.documentReference != null &&
    document.documentReference.some(({ number }) => number === policyNumber)
