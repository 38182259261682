<script>
export default {
  name: 'ClickSimulator',
  props: {
    linkAttribute: {
      type: String
    },
    buttonToClick: {
      type: String
    },
    iframeName: {
      type: String,
      required: false
    }
  },
  mounted() {
    this.handleListeners(true)
  },
  methods: {
    handleListeners(add) {
      const chatButton = document?.querySelectorAll(`[data-script="${this.linkAttribute}"]`)
      if (chatButton) {
        chatButton.forEach((el) => {
          if (add) {
            el.addEventListener('click', this.clickButton)
          } else {
            el.removeEventListener('click', this.clickButton)
          }
        })
      }
    },
    clickButton() {
      if (this.iframeName) {
        const chatFrameObject = document.getElementsByName(this.iframeName)
        chatFrameObject[0]?.contentWindow?.document?.getElementById(this.buttonToClick)?.click()
      } else {
        document?.getElementById(this.buttonToClick)?.click()
      }
    }
  },
  beforeUnmount() {
    this.handleListeners(false)
  },
  render: () => null
}
</script>
