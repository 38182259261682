<template>
  <EmptyTablePlaceholder v-if="!policySchedule" :no-results-text="textContent.noResultsText" />
  <CycleListItem v-else>
    <CycleLinkIcon modifiers="file" :href="buildDownloadLink(policySchedule)" target="">
      {{ textContent.policyScheduleLabel }}
    </CycleLinkIcon>
  </CycleListItem>
</template>

<script>
import { CycleLinkIcon } from '@/components/cycle-link/'
import { CycleListItem } from '@/components/cycle-list/'
import CycleCardList from '@/components/cycle-card-list/CycleCardList'
import { buildDownloadLink } from '@/services/Document'
import EmptyTablePlaceholder from './EmptyTablePlaceholder'

export default {
  name: 'PolicySchedule',
  inject: ['textContent'],
  components: {
    CycleLinkIcon,
    CycleListItem,
    CycleCardList,
    EmptyTablePlaceholder
  },
  props: {
    policySchedule: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    buildDownloadLink
  }
}
</script>
