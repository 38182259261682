import gql from 'graphql-tag'

export const policyItemCar = gql`
  fragment policyItemCar on Policy {
    item {
      ... on Car {
        licensePlate
        claimFreeYears
        noClaimDiscount
        step
        securityClass
        vehicleData {
          brand
          model
          vehicleType
          constructionYear
        }
        carConfigurations {
          mainCoverage {
            mainCoverageLegalLiability {
              active
            }
            mainCoverageRoadsideAssistance {
              active
              hideCoverage
            }
            item {
              type
            }
          }
          additionalCoverages {
            accidentsOccupants {
              active
            }
            bonusProtector {
              active
            }
            damageInsuranceOccupants {
              active
            }
            legalCounsel {
              active
            }
            damageInsuranceOccupants {
              active
            }
            newGuarantee {
              active
            }
            purchaseValueGuarantee {
              active
            }
            freeChoiceRepairCompany {
              active
            }
            assistance {
              active
            }
            vehicleContents {
              active
            }
            roadsideAssistance {
              active
              hideCoverage
            }
          }
        }
      }
    }
  }
`
