//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { InvoiceStatus } from '@/models/my-overview'
import { StatusIndicator } from '@/components/atoms'
import { DocumentsHeading, DocumentInvoices } from '@/components/atoms/Documents'
import { CyclePagination } from '@/components/cycle-pagination'
import { DocumentsItem, EmptyTablePlaceholder, StatusLegend } from '@/components/molecules'
import { CycleCurrency, CycleDate } from '@/filters'
import { CycleList, CycleListItem } from '@/components/cycle-list'
import { intlMixin, dictionaryMixin } from '@/mixins'
import { CycleLinkIcon } from '@/components/cycle-link'

export default {
  name: 'InvoicesTable',
  components: {
    DocumentInvoices,
    DocumentsHeading,
    DocumentsItem,
    StatusIndicator,
    CyclePagination,
    CycleList,
    CycleListItem,
    EmptyTablePlaceholder,
    CycleLinkIcon
  },
  filters: {
    CycleCurrency,
    CycleDate
  },
  mixins: [intlMixin, dictionaryMixin],
  props: {
    textContent: {
      type: Object,
      required: false,
      default: () => ({})
    },
    documents: {
      type: Array,
      required: false,
      default: () => []
    },
    numberOfLinesOnPage: {
      type: Number,
      required: false
    },
    pageNumber: {
      type: Number,
      required: true
    },
    totalNumberOfLines: {
      type: Number,
      required: false,
      default: 0
    },
    isLoading: {
      type: Boolean,
      required: false
    },
    hasNkcLink: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      selected: []
    }
  },
  computed: {
    hasPagination() {
      if (
        this.numberOfLinesOnPage === 0 ||
        this.numberOfLinesOnPage == null ||
        this.numberOfLinesOnPage == undefined
      ) {
        return false
      }

      return this.totalNumberOfLines / this.numberOfLinesOnPage > 1
    }
  },
  methods: {
    // Show corresponding dot type based on incoming status
    showDotType(status) {
      if (status === InvoiceStatus.Success || status === InvoiceStatus.SuccessEnglish) {
        return 'confirmed'
      }
      return 'error'
    },
    setPageNumber(e) {
      this.$emit('page-changed', parseInt(e))
    },
    handleDetailClick(index) {
      if (this.selected.includes(index)) {
        this.selected.splice(this.selected.indexOf(index), 1)
      } else {
        this.selected.push(index)
      }
    },
    openDetails(index) {
      if (this.selected.includes(index)) {
        return 'open'
      }

      return ''
    }
  }
}
