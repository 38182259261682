<template>
  <CycleContentBlock v-if="isContentBlockListVisible" :contrast-color="isBackgroundVisible">
    <template v-if="hasTitle" #header>
      <CycleHeading :weight="headingWeight">{{ title }}</CycleHeading>
    </template>
    <template v-if="hasBodyText || hasList" #body>
      <CycleHtml v-if="hasBodyText" :html="bodyText" />
      <CycleList v-if="hasList" :modifiers="listType">
        <CycleListItem v-for="(listItem, index) in list" :key="index">
          {{ listItem }}
        </CycleListItem>
      </CycleList>
    </template>
    <template v-if="hasFooterText" #footer>
      <CycleHtml :html="footerText" />
    </template>
  </CycleContentBlock>
</template>
<script setup>
import { defaultHeadingWeight } from '@/constants/Defaults'
const props = defineProps({
  fields: {
    type: Object,
    default: () => ({})
  }
})
const listType = props.fields?.listBulletType?.value
const list = props.fields?.listItems?.value || []
const hasList = list?.length > 0
const title = props.fields?.heading?.value
const hasTitle = title?.length > 0
const bodyText = props.fields?.panelBodyText?.value
const hasBodyText = bodyText?.length > 0
const footerText = props.fields?.panelFooterText?.value
const hasFooterText = footerText?.length > 0
const isBackgroundVisible = props.fields?.setTransparent?.value
const isContentBlockListVisible = hasTitle || hasBodyText || hasList || hasFooterText
const headingWeight = Number.parseInt(props.fields?.weight?.value) || defaultHeadingWeight
</script>
